// import { useEffect } from "react";
// import { useLocation } from "react-router-dom";

// function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     // Add specific routes where you want to scroll to the top
//     const scrollRoutes = [ "/", "/services", "/about", "/product", "/contact", "/event", "/partner", "/faq", "/CIO Gallery", "/Birthday Gallery", "/Reunion Gallery", "/Privacy Policy", "/Terms & Conditions" ];
    
//     if (scrollRoutes.includes(pathname)) {
//       window.scrollTo(0, 0);
//     }
//   }, [pathname]);

//   return null;
// }

// export default ScrollToTop;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop({ routesToScrollTo = [] }) {
  const { pathname } = useLocation();

  useEffect(() => {
    // If no specific routes are passed, scroll to top for all routes
    const scrollRoutes = routesToScrollTo.length ? routesToScrollTo : ["/", "/services", "/about", "/product", "/contact", "/event", "/partner", "/faq", "/CIO Gallery", "/Birthday Gallery", "/Reunion Gallery", "/Privacy Policy", "/Terms & Conditions"];
    
    if (scrollRoutes.includes(pathname)) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [pathname, routesToScrollTo]);

  return null;
}

export default ScrollToTop;

